<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex> 
      </v-layout>
    </v-snackbar>
    <v-layout wrap v-if="schemeDetails.schemeStatus">
      <v-flex xs12 sm6 md8 text-left>
        <span class="mainHeader">SCHEME PAYMENT</span>
      </v-flex>
      <v-flex sm6 md4 v-if="schemeDetails.schemeStatus=='Closed'" text-right>
        <span class="mainHeader2" style="color:red">This scheme is overdue</span>
      </v-flex>
     
      <!-- <v-flex xs12 sm3 md2 text-right pl-1>
        <v-text-field
          background-color="white"
          v-model="keyword"
          color="#b234a9"
          clearable
          hide-details
          placeholder="Search"
          outlined
          class="rounded-xl"
          dense
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-flex> -->
       <!-------------------------LIST OF CUSTOMER IN SCHEME----------------------------->
      <v-flex xs12 py-6 text-left>
        <v-card
          class="rounded-lg pa-4"
          elevation="0"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
        <v-layout wrap justify-space-between>
          <v-flex xs5 text-left>
            <v-layout wrap v-if="schemeDetails.customerId">
              <v-flex xs12 class="tablefont2" text-left pb-1>Customer Details:</v-flex>
                    <v-flex xs6 class="tablefont1" text-left pb-1 v-if="schemeDetails.customerId.customerName">{{ schemeDetails.customerId.customerName }} , Phone: {{ schemeDetails.customerId.mobileNumber }}</v-flex>
                    <!-- <v-flex xs6 class="tablefont1" text-left pb-1 v-if="schemeDetails.customerId.mobileNumber">{{ schemeDetails.customerId.mobileNumber }}</v-flex> -->
                    <v-flex xs12 class="tablefont1" text-left pb-1 v-if="schemeDetails.customerId.address">{{ schemeDetails.customerId.address }}</v-flex>
                  </v-layout>
          </v-flex>
          <v-flex xs4 text-right>
            <v-layout wrap>
                <v-flex xs12 text-left v-if="schemeDetails.goldSchemeId">
                  <v-layout wrap >
                    <v-flex xs12 sm6 class="tablefont2" text-left pb-1>SCHEME:</v-flex>
                    <v-flex xs12 sm6 class="tablefont1" text-left pb-1 v-if="schemeDetails.goldSchemeId.goldSchemeName">{{ schemeDetails.goldSchemeId.goldSchemeName }}</v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 text-left v-if="schemeDetails.startDate">
                  <v-layout wrap>
                    <v-flex xs12 sm6 class="tablefont2" text-left pb-1>Starting Date:</v-flex>
                    <v-flex xs12 sm6 class="tablefont1" text-left pb-1>{{  formatDate(schemeDetails.startDate)}}</v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 text-left v-if="schemeDetails.matureDate">
                  <v-layout wrap>
                    <v-flex xs12 sm6 class="tablefont2" text-left pb-1>Maturity Date:</v-flex>
                    <v-flex xs12 sm6 class="tablefont1" text-left pb-1>{{  formatDate(schemeDetails.matureDate) }}</v-flex>
                  </v-layout>
                </v-flex>
               
              </v-layout>
          </v-flex>
        </v-layout>
             
              <v-layout wrap>
                    <v-flex xs12 py-2>
                     <v-divider></v-divider>
                    </v-flex>
                    </v-layout>
             
              <v-layout wrap>
                <v-flex xs12 v-if="PaymentList">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left tablefont"><b>S.No.</b></th>
                          <th class="text-left tablefont"><b>Month</b></th>
                          <th class="text-left tablefont">
                            <b>Montly Deposit(₹)</b>
                          </th>
                          <th class="text-left tablefont">
                            <b>Interest(%)</b>
                          </th>
                          <th class="text-left tablefont">
                            <b>Months Covered</b>
                          </th>
                          <th class="text-left tablefont">
                            <b>Interest Amt.(₹)</b>
                          </th>
                          <th class="text-left tablefont">
                            <b>Paid Amt.(₹)</b>
                          </th>
                          <th class="text-left tablefont">
                            <b>Due Date</b>
                          </th>
                          <th class="text-left tablefont"><b>Action</b></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(value, i) in PaymentList"
                          :key="i"
                          class="table"
                        >
                          <!-- <td>{{ i + 1 }}</td> -->
                          <td>
                        <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                      </td>
                          <td>
                            <span v-if="value.paidMonth">{{
                              value.paidMonth
                            }}</span>
                            <span v-else>-</span>
                          </td>

                          <td>
                            <span v-if="value.monthlyFixedAmount">{{
                              value.monthlyFixedAmount
                            }}</span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="value.monthlyInterestRate">
                              {{ value.monthlyInterestRate }}</span
                            >
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="value.monthsCovered">
                              {{ value.monthsCovered }}</span
                            >
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="value.monthlyInterestAmount">
                              {{ value.monthlyInterestAmount.toFixed(3) }}</span
                            >
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="value.amountPaid">
                              {{ value.amountPaid}}</span
                            >
                            <span v-else>0</span>
                          </td>
                          <td>
                            <span v-if="value.dueDate">
                              {{ formatDate(value.dueDate)}}</span
                            >
                            <span v-else>0</span>
                          </td>
                          <td>
                            <v-layout wrap v-if="schemeDetails">
                              <v-flex xs12 v-if="schemeDetails.schemeStatus=='Closed'">
                                <v-btn
                                  v-if="value.isPaid == true"
                                  color="green"
                                  
                                  class="buttons1"
                                  
                                  block
                                  small
                                >
                                  Paid
                                </v-btn>
                                <v-btn
                                  v-else
                                  
                                  disabled
                                  class="buttons1"
                                  
                                  block
                                  small
                                >
                                  Cancelled
                                </v-btn>
                              </v-flex>
                              <v-flex xs12 v-else>
                                <v-btn
                                  v-if="value.isPaid == true"
                                  color="green"
                                  class="buttons1"
                                  
                                  block
                                  small
                                >
                                  Paid
                                </v-btn>
                                <v-btn
                                  v-else-if="
                                    isPreviousMonth(
                                      value.paidMonth,
                                      value.paidYear
                                    )
                                  " class="buttons1"
                                  color="#3F053C"
                                  dark
                                  block
                                  small
                                  @click="makePayment2(value)"
                                >
                                  Make Payment
                                </v-btn>
                                <v-btn
                                  v-else
                                  color="#3F053C"
                                  dark
                                  disabled
                                  block
                                  small
                                >
                                  -
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
              </v-layout>
            </v-card>
        <v-layout wrap>
          <v-flex xs12 pt-4>
            <v-pagination
              small
              color="#3F053C"
              v-model="page"
              :length="Pagelength"
            >
            </v-pagination>
          </v-flex>
        </v-layout>
        <v-dialog width="400px" v-model="paymentDialog">
          <v-form v-model="addcat" ref="addcat" @submit.prevent>
            <v-card tile width="1000px" class="px-8 py-4 rounded-lg">
              <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs11 pt-4 text-center><span style="color:#3F053C;" class="menuhead1">Payment</span></v-flex>
              <v-flex xs1 text-right
                  ><v-icon
                    @click="(paymentDialog = false),(resetForm())"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
             <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
            </v-layout>
              <v-layout wrap>
                <!-- <v-flex xs12 py-1 text-left v-if="schemeDetails.totalExcessAmount">Balance Available (₹):<span v-if="schemeDetails.totalExcessAmount"> {{ schemeDetails.totalExcessAmount }} </span>
                <span v-else>0</span></v-flex>
                <v-flex xs12 py-1 text-left v-if="paymentDetails1.monthlyFixedAmount">Total (₹): {{ paymentDetails1.monthlyFixedAmount }}</v-flex>
                <v-flex xs12 py-1 text-left v-if="pendingAmt">Payable Amt (₹): {{ pendingAmt }}</v-flex> -->
                <!-- <v-flex xs12 py-1>
                  <v-text-field
                                placeholder="0"
                                type="number"
                                color="#b234a9"
                                outlined style="border-color: rgba(192, 0, 250, 0.986);"
                                flat disabled
                                v-model="pendingAmt"
                                dense
                                hide-details="auto"
                              ></v-text-field>
                </v-flex> -->
                <!-- <v-flex xs12 text-center py-4 >
              <v-divider></v-divider>
            </v-flex> -->
                <v-flex xs12 py-1 text-left class="tablefont">Amount (₹):</v-flex>
                <v-flex xs12 py-1>
                  <v-text-field
                                placeholder="0"
                                type="number"
                                outlined style="border-color: rgba(192, 0, 250, 0.986);"
                                flat color="#3F053C" disabled
                                v-model="paymentDetails1.monthlyFixedAmount"
                                dense
                                hide-details="auto"
                              ></v-text-field>
                </v-flex>
                <v-flex xs12 py-1 text-left class="tablefont">Type:</v-flex>
                <v-flex xs12 py-1>
                  <v-select
                        :items="paymentTypeList"
                        v-model="paytype"
                        label="Payment Type"
                        dense color="#3F053C"
                        hide-details="auto"
                        outlined
                      ></v-select>
                </v-flex>
                <v-flex xs6 py-1 class="buttons1">
                  <v-btn class="buttons1"
                                  color="#3F053C"
                                  dark
                                  @click="valid()"
                                  block
                                  small
                                >
                                  Make Payment
                                </v-btn>
                </v-flex>
                </v-layout>
                </v-card>
                </v-form>
                </v-dialog>

     </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      page: 1,
      Pagelength: null,

      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      addcat: false,
      addcat2: false,
      keyword: null,
      schemefilter: "",
      schemeList: [],
      List1: [],
      PaymentList: [],
      yrlyintrst: "",
      mnthlydpst: "",
      menu1: false,
      fromDate: "",
      secondDate: "",
      goldSchemeName: "",
      monthlyDeposit: "",
      customerId: "",
      paymentDialog: false,
      customerList: [],
      customerdetails: "",
      selected: [],
      makePayment: false,
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      getCurrentMonthIndex: new Date().getMonth(),
      payDetails: [],
      //payment
      paymentTypeList: ["Cash", "Cheque", "Card"],
      paytype: null,
      pendingAmount: "",
      paymentDetails:[],
      paymentDetails1:[],
      schemeDetails:[],
      CashAmt:"",
      pendingAmt:"",
    };
  },
  mounted() {
    this.schemePayment();
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
    minDate() {
      if (this.fromDate) {
        const selectedDate = new Date(this.fromDate);
        selectedDate.setMonth(selectedDate.getMonth() + 12);
        return selectedDate.toISOString().slice(0, 10);
      } else {
        return null;
      }
    },
  },
  watch: {
    keyword() {
      this.schemePayment();
    },
    page() {
      this.schemePayment();
    },
    schemefilter() {
      this.schemePayment();
    },
    fromDate(newValue) {
      if (newValue) {
        const selectedDate = new Date(newValue);
        const futureDate = new Date(
          selectedDate.setMonth(selectedDate.getMonth() + 12)
        );
        this.secondDate = futureDate.toISOString().slice(0, 10);
        console.log(this.secondDate, "==this.secondDate");
      } else {
        this.secondDate = null;
      }
    },
  },

  methods: {
    valid() {
     if (!this.paytype) {
        this.msg = "Please choose payment type";
        this.showsnackbar = true;
        return;
      } 
      else {
        this.MakePayment1();
      }
    },
    resetForm() {
      this.$refs.addcat.reset();
    },
    isPreviousMonth(paidMonth, paidYear) {
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      const currentMonthName = this.monthNames[currentMonth];
      const currentYearString = currentYear.toString();

      const paidMonthIndex = this.monthNames.indexOf(paidMonth);
      const currentMonthIndex = this.monthNames.indexOf(currentMonthName);

      return (
        paidYear < currentYearString ||
        (paidYear === currentYearString && paidMonthIndex <= currentMonthIndex)
      );
    },
    selectThis(id) {
      console.log("id==", id);
      this.selected = this.List1.filter((x) => x._id == id);
      this.yrlyintrst = this.selected[0].yearlyIntrest;
      this.mnthlydpst = this.selected[0].minmonthlydeposit;
      console.log("yrlyintrst==", this.yrlyintrst);
      console.log("mnthlydpst==", this.mnthlydpst);
      console.log("selected==", this.selected);
    },
    getCustomer() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/customer/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          // keyword: this.keyword,
          page: this.page,
          count: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.customerList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.showsnackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    schemePayment() {
      axios({
        method: "get",
        url: "/goldscheme/customer/paymentgetlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.page,
            count: 20,
          goldschemecustomerId: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.PaymentList = response.data.data;
            this.schemeDetails = response.data.schemeDetails;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.showsnackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    makePayment2(value){
      this.paymentDetails1=value;
//       if(this.schemeDetails.totalExcessAmount>0){
//         console.log("1")
//       var new1 = this.schemeDetails.totalExcessAmount;
//       var new2 = this.paymentDetails1.monthlyFixedAmount;
//       if(new1<=new2){
//         console.log("2")

//       this.pendingAmt = new2-new1;
//       }
//       else{
//         console.log("3")

//         this.pendingAmt = 0;
//       }
//     }
//     else{
//       console.log("4")

//       this.pendingAmt = this.paymentDetails1.monthlyFixedAmount;
//     }
//       console.log("pendingAmt==",this.pendingAmt)
this.paymentDialog=true;
    },
    MakePayment1() {
      // this.appLoading = true;
      // if(!this.CashAmt>0){
      //   this.CashAmt = 0;
      //   this.paytype = "";
      // }
      axios({
        method: "POST",
        url: "/goldscheme/payment",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data:{
          amountPaid: this.paymentDetails1.monthlyFixedAmount,
          paymentType: this.paytype,
          payId: this.paymentDetails1._id,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.paymentDialog=false;
            this.CashAmt = "",
            this.paytype = "",
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.schemePayment();
          }
          else if(response.data.status == false && response.data.isOverDue==true){
            this.schemePayment();
            console.log("qwertyui")

          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.showsnackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
  <style scoped>
.v-text-field input {
  font-family: kumbhBold;
}
</style>
  